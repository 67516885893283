<template>
  <div>
    <audio v-if="isNewNotification" controls autoplay>
      <source src="@/assets/file/notification.wav" type="audio/wav">
    </audio>
  </div>
</template>

<script>
import { onMessage, getToken } from 'firebase/messaging'
import { showToast } from '@/utils/toast'

import store from '@/store'
import messaging from '../plugins/firebase'

export default {
  name: 'Notification',
  data() {
    return {
      isNewNotification: false,
    }
  },
  async mounted() {
    setTimeout(() => {
      console.log('It`s notification component', messaging)
      getToken(messaging, { vapidKey: 'BFnwviQi94WS6OafmIzexaBsJH2fINfrR4XRKFDLcZxn6JzX1XWCHl6vIaywVVi-cSjbRLZf4NU8mA_N0lnGBV4' }).then(currentToken => {
        console.log(88, currentToken)
        if (currentToken) {
          this.$store.dispatch('notification/saveToken', currentToken)
          console.log('Token:', currentToken)
          onMessage(messaging, payload => {
            console.log(payload)
            const { body } = payload.notification
            if (body.images_confirmation_status && !this.isImagesChecker) {
              // showToasNotification('warning', payload, 'BellIcon')
              showToast('warning', 'Sizga yangi xabar keldi', 'BellIcon')
            } else {
              // showToasNotification('warning', payload, 'BellIcon')
              showToast('warning', 'Sizga yangi xabar keldi', 'BellIcon', payload.data.text)
              this.getConversations()
              if (this.$route.query.chat_id) {
                this.openChatOfConversation(this.$route.query.chat_id)
              }
            }
            this.isNewNotification = true
            setTimeout(() => {
              this.isNewNotification = false
            }, 1500)
          })
        } else {
          console.log('Show permission request UI')
        }
      }).catch(err => {
        console.log('An error occurred while retrieving token. ', err)
        showToast('warning', 'Browserdan Notficationga ruxsat bering', 'BellIcon')
      })
    }, 500)
  },
  methods: {
    async getConversations() {
      await store.dispatch('chat/getInstagramConversations', {})
    },
    openChatOfConversation(id) {
      store.dispatch('chat/getInstagramConversationById', { id })
    },
  },
}
</script>

<style scoped></style>
