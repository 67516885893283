import { initializeApp } from 'firebase/app'
import { getMessaging, isSupported } from 'firebase/messaging'

const firebaseConfig = {
  apiKey: 'AIzaSyAHmmi_n4SlOApRHseCM0WdddhSgW09e28',
  authDomain: 'salomhayot-f52df.firebaseapp.com',
  projectId: 'salomhayot-f52df',
  storageBucket: 'salomhayot-f52df.firebasestorage.app',
  messagingSenderId: '49197264138',
  appId: '1:49197264138:web:270c6548a71ff7bf851ef3',
  measurementId: 'G-9T08Z3DNKL',
}
const app = initializeApp(firebaseConfig)
const isSupport = true
const messaging = isSupport ? getMessaging(app) : null
export default messaging
