import request from '@/utils/request'

const lotsUrl = '/shop/lots'

export function index(params) {
  return request({
    url: lotsUrl,
    method: 'get',
    params,
  })
}

export function show(params) {
  return request({
    url: `${lotsUrl}/${params.id}`,
    method: 'get',
    params,
  })
}

export function store(data) {
  return request({
    url: lotsUrl,
    method: 'post',
    data,
  })
}

export function update(data) {
  return request({
    url: `${lotsUrl}/${data.id}`,
    method: 'put',
    data,
  })
}

export function destroy(id) {
  return request({
    url: `${lotsUrl}/${id}`,
    method: 'delete',
  })
}

export function investmentsIndex(params) {
  return request({
    url: '/investment/investments',
    method: 'get',
    params,
  })
}

export function investmentStore(data) {
  return request({
    url: '/investment/investments',
    method: 'post',
    data,
  })
}
