import request from '@/utils/request'

// instagram chat
export function getInstagramConversations(query) {
  return request({
    url: '/instagram/get-chats',
    method: 'get',
    params: query,
  })
}
export function getInstagramConversationById(query) {
  return request({
    url: `/instagram/get-messages/${query.id}`,
    method: 'get',
    params: query,
  })
}
export function sendIgMessage(data) {
  return request({
    url: '/instagram/send-message',
    method: 'post',
    data,
  })
}
export function refreshInstagramToken(query) {
  return request({
    url: '/instagram/refresh-token',
    method: 'get',
    params: query,
  })
}
