import request from '@/utils/request'

const slug = '/contracts'

export function index(params) {
  return request({
    url: slug,
    method: 'get',
    params,
  })
}

export function show(params) {
  return request({
    url: `${slug}/${params.id}`,
    method: 'get',
    params,
  })
}

export function store(data) {
  return request({
    url: slug,
    method: 'post',
    data,
  })
}

export function update(data) {
  return request({
    url: `${slug}/${data.id}`,
    method: 'put',
    data,
  })
}

export function destroy(id) {
  return request({
    url: `${slug}/${id}`,
    method: 'delete',
  })
}

export function paymentStore(data) {
  return request({
    url: 'payments',
    method: 'post',
    data,
  })
}

export function getDailyPayments(params) {
  return request({
    url: 'get-daily-payments',
    method: 'get',
    params,
  })
}

export function sendCourt(data) {
  return request({
    url: 'contract/give-court',
    method: 'put',
    data,
  })
}
export function uploadCourtDecision(data) {
  return request({
    url: 'contract/upload-court-decision',
    method: 'put',
    data,
  })
}
export function uploadAddDocument(data) {
  return request({
    url: `contract/${data.id}/add-document`,
    method: 'post',
    data,
  })
}
export function deleteAddDocument(id) {
  return request({
    url: `contract/${id}/delete-document`,
    method: 'delete',
  })
}
export function confirmDailyPayments(data) {
  return request({
    url: 'confirm-daily-payments',
    method: 'put',
    data,
  })
}
export function confirmPayment(data) {
  return request({
    url: 'confirm-payment',
    method: 'put',
    data,
  })
}

export function paymentList(params) {
  return request({
    url: 'payments',
    method: 'get',
    params,
  })
}

export function paymentShow(params) {
  return request({
    url: `payments/${params.id}`,
    method: 'get',
    params,
  })
}
export function paymentUpdate(data) {
  return request({
    url: `payments/${data.id}`,
    method: 'put',
    data,
  })
}
export function paymentDelete(data) {
  return request({
    url: `payments/${data.id}`,
    method: 'delete',
    data,
  })
}
// comments
export function commentStore(data) {
  return request({
    url: 'comments',
    method: 'post',
    data,
  })
}

export function getComments(params) {
  return request({
    url: 'comments',
    method: 'get',
    params,
  })
}

export function updateImagesConfirmation(data) {
  return request({
    url: `update-images-confirmation-status/${data.id}`,
    method: 'put',
    data,
  })
}

export function sellForCash(data) {
  return request({
    url: 'sell-for-cash',
    method: 'post',
    data,
  })
}

export function rejectContract(data) {
  return request({
    url: 'reject-contract',
    method: 'put',
    data,
  })
}

export function getContractProductInfo(params) {
  return request({
    url: 'get-contract-product-info',
    method: 'get',
    params,
  })
}

export function getOldContracts(params) {
  return request({
    url: 'old-contracts',
    method: 'get',
    params,
  })
}

export function oldContractShow(params) {
  return request({
    url: `old-contracts/${params.id}`,
    method: 'get',
    params,
  })
}
export function getPaymentProductInfo(params) {
  return request({
    url: 'get-payment-product-info',
    method: 'get',
    params,
  })
}
export function rejectByProduct(data) {
  return request({
    url: 'payment/reject-by-product',
    method: 'put',
    data,
  })
}
export function paymentStoreForOldContract(data) {
  return request({
    url: 'payment-store-for-old-contract',
    method: 'post',
    data,
  })
}

export function makeAktSverka(data) {
  return request({
    url: `make-akt-sverka-pdf/${data.id}`,
    method: 'post',
    data,
  })
}

export function rejectConfirmedPayment(data) {
  return request({
    url: `payment/${data.id}/reject-confirmed-payment`,
    method: 'put',
    data,
  })
}
