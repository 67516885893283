export default [
  {
    path: '/marketplace/',
    name: 'Marketplace',
    component: () => import('@/views/marketplace/index.vue'),
    redirect: 'MarketplaceBannerCreate',
    meta: {
      middleware: 'Auth',
      pageTitle: 'Market',
    },
    children: [
      {
        path: 'banner',
        name: 'MarketplaceBannerCreate',
        component: () => import('@/views/marketplace/banner/index.vue'),
        meta: {
          middleware: 'Auth',
          pageTitle: 'Banner',
        },
      },
      {
        path: 'quick-orders',
        name: 'QuickOrdersList',
        component: () => import('@/views/marketplace/quickOrders/index.vue'),
        meta: {
          middleware: 'Auth',
          pageTitle: 'Tezkor buyurtmalar',
        },
      },
      {
        path: 'users',
        name: 'marketplaceUsersList',
        component: () => import('@/views/marketplace/users/index.vue'),
        meta: {
          middleware: 'Auth',
          pageTitle: 'Ilova foydalanuvchilari',
        },
      },
      {
        path: 'auctions',
        name: 'AuctionsList',
        component: () => import('@/views/marketplace/auctions/index.vue'),
        meta: {
          middleware: 'Auth',
          pageTitle: 'Auksion lotlari',
          hasCreateButton: true,
          createButtonName: 'Qo\'shish',
        },
      },
      {
        path: 'auction/:id',
        name: 'auction-show',
        component: () => import('@/views/marketplace/auctions/show.vue'),
        meta: {
          middleware: 'Auth',
          pageTitle: 'Auksion',
        },
      },
      {
        path: 'upload-photo',
        name: 'UploadProductPhotos',
        component: () => import('@/views/marketplace/uploadPhoto.vue'),
        meta: {
          middleware: 'Auth',
          pageTitle: 'Mahsulotlar foto',
        },
      },
    ],
  },
  {
    path: '/chat/instagram',
    name: 'InstagramChats',
    component: () => import('@/views/chat/instagram/Chat.vue'),
    meta: {
      middleware: 'Auth',
      pageTitle: 'Instagram xabarnomalar',
    },
  },
]
