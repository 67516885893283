import {
  getInstagramConversations,
  getInstagramConversationById,
  sendIgMessage,
} from '@/api/chat'

// eslint-disable-next-line import/prefer-default-export
export const actions = {
  getInstagramConversations({ commit }, query) {
    return new Promise((resolve, reject) => {
      getInstagramConversations(query).then(res => {
        commit('SET_ITEMS', res.data)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getInstagramConversationById({ commit }, id) {
    return new Promise((resolve, reject) => {
      getInstagramConversationById(id).then(res => {
        commit('SET_ITEM', res.data)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  sendIgMessage({ commit }, data) {
    return new Promise((resolve, reject) => {
      sendIgMessage(data)
        .then(res => {
          resolve(res)
        })
        .catch(res => {
          reject(res)
        })
    })
  },
}
